// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* .studentInfo {\n  width: 90%;\n  margin: 0 auto;\n}\n.studentInfo__item {\n  height: 40px;\n  display: flex;\n  align-items: center;\n}\n.studentInfo__item p {\n  margin-left: 20px;\n  font-size: large;\n} */\n\n#StudentInfo_student__comment__aZgXl {\n  color: black;\n}\n\n.StudentInfo_table__actions__H3JOq {\n  width: 100%;\n  display: flex;\n  margin-top: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/students/studentDetails/StudentInfo.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;GAYG;;AAEH;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB","sourcesContent":["/* .studentInfo {\n  width: 90%;\n  margin: 0 auto;\n}\n.studentInfo__item {\n  height: 40px;\n  display: flex;\n  align-items: center;\n}\n.studentInfo__item p {\n  margin-left: 20px;\n  font-size: large;\n} */\n\n#student__comment {\n  color: black;\n}\n\n.table__actions {\n  width: 100%;\n  display: flex;\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"student__comment": "StudentInfo_student__comment__aZgXl",
	"table__actions": "StudentInfo_table__actions__H3JOq"
};
export default ___CSS_LOADER_EXPORT___;
