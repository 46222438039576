// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../assets/styles/_variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search_search__container__LXgRd {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  box-sizing: border-box;\n}\n.Search_search__container__LXgRd label {\n  margin-bottom: 5px;\n  font-size: large;\n  position: absolute;\n  top: -25px;\n  left: 0;\n  color: whitesmoke;\n}\n.Search_search__container__LXgRd input {\n  box-sizing: border-box;\n  width: 250px;\n  max-width: 250px;\n  height: 40px;\n  border-radius: 10px;\n  border: none;\n  outline: none;\n  padding-left: 10px;\n  font-family: inherit;\n  font-size: large;\n  box-sizing: border-box;\n  transition: all 0.2s ease;\n}\n.Search_search__container__LXgRd input:focus {\n  border: 1px solid var(--accent_3);\n}\n", "",{"version":3,"sources":["webpack://./src/ui/Search.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,OAAO;EACP,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;EAChB,sBAAsB;EACtB,yBAAyB;AAC3B;AACA;EACE,iCAAiC;AACnC","sourcesContent":["@import '../assets/styles/_variables.css';\n\n.search__container {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  box-sizing: border-box;\n}\n.search__container label {\n  margin-bottom: 5px;\n  font-size: large;\n  position: absolute;\n  top: -25px;\n  left: 0;\n  color: whitesmoke;\n}\n.search__container input {\n  box-sizing: border-box;\n  width: 250px;\n  max-width: 250px;\n  height: 40px;\n  border-radius: 10px;\n  border: none;\n  outline: none;\n  padding-left: 10px;\n  font-family: inherit;\n  font-size: large;\n  box-sizing: border-box;\n  transition: all 0.2s ease;\n}\n.search__container input:focus {\n  border: 1px solid var(--accent_3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search__container": "Search_search__container__LXgRd"
};
export default ___CSS_LOADER_EXPORT___;
