// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/_variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header {\n  width: 100%;\n  height: 70px;\n  margin-bottom: 10px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  /* border: 1px solid white; */\n}\n\n.header p {\n  margin: 0;\n  margin-bottom: 10px;\n}\n\n.header img {\n  display: none;\n}\n\n.exit__btn {\n  cursor: pointer;\n  transition: all 0.3s ease-in;\n}\n\n.exit__btn:hover {\n  color: var(--accent_3);\n}\n\n/* ------------------------------ MEDIA QUERIES ----------------------- */\n\n@media (max-width: 768px) {\n  .header {\n    width: 105%;\n    justify-content: space-between;\n  }\n\n  .header img {\n    display: block;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/css/components/Header.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,sBAAsB;AACxB;;AAEA,yEAAyE;;AAEzE;EACE;IACE,WAAW;IACX,8BAA8B;EAChC;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":["@import '../../assets/styles/_variables.css';\n\n.header {\n  width: 100%;\n  height: 70px;\n  margin-bottom: 10px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  /* border: 1px solid white; */\n}\n\n.header p {\n  margin: 0;\n  margin-bottom: 10px;\n}\n\n.header img {\n  display: none;\n}\n\n.exit__btn {\n  cursor: pointer;\n  transition: all 0.3s ease-in;\n}\n\n.exit__btn:hover {\n  color: var(--accent_3);\n}\n\n/* ------------------------------ MEDIA QUERIES ----------------------- */\n\n@media (max-width: 768px) {\n  .header {\n    width: 105%;\n    justify-content: space-between;\n  }\n\n  .header img {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
