// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../assets/styles/_variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_btn__WK4EW {\n  width: -moz-fit-content;\n  width: fit-content;\n  margin: 0;\n  border: none;\n  text-decoration: none;\n  font-family: 'Comfortaa', cursive;\n  padding: 10px 20px;\n  border-radius: 10px;\n  outline: none;\n  font-weight: 700;\n  font-size: large;\n  cursor: pointer;\n  background-color: whitesmoke;\n  /* box-shadow: 2px 2px 5px black; */\n  transition: all 0.1s ease-in;\n}\n\n.Button_btn__WK4EW:enabled:hover,\n.Button_btn__WK4EW:enabled:focus {\n  outline: 2px solid var(--accent_3);\n  color: var(--accent_3);\n  box-shadow: none;\n}\n\n.Button_btn__WK4EW:disabled {\n  cursor: auto;\n  color: gray;\n  background-color: rgba(0, 0, 0, 0.622);\n  background-color: rgba(211, 211, 211, 0.725);\n}\n", "",{"version":3,"sources":["webpack://./src/ui/Button.module.css"],"names":[],"mappings":"AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,qBAAqB;EACrB,iCAAiC;EACjC,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,4BAA4B;EAC5B,mCAAmC;EACnC,4BAA4B;AAC9B;;AAEA;;EAEE,kCAAkC;EAClC,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sCAAsC;EACtC,4CAA4C;AAC9C","sourcesContent":["@import '../assets/styles/_variables.css';\n\n.btn {\n  width: fit-content;\n  margin: 0;\n  border: none;\n  text-decoration: none;\n  font-family: 'Comfortaa', cursive;\n  padding: 10px 20px;\n  border-radius: 10px;\n  outline: none;\n  font-weight: 700;\n  font-size: large;\n  cursor: pointer;\n  background-color: whitesmoke;\n  /* box-shadow: 2px 2px 5px black; */\n  transition: all 0.1s ease-in;\n}\n\n.btn:enabled:hover,\n.btn:enabled:focus {\n  outline: 2px solid var(--accent_3);\n  color: var(--accent_3);\n  box-shadow: none;\n}\n\n.btn:disabled {\n  cursor: auto;\n  color: gray;\n  background-color: rgba(0, 0, 0, 0.622);\n  background-color: rgba(211, 211, 211, 0.725);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "Button_btn__WK4EW"
};
export default ___CSS_LOADER_EXPORT___;
