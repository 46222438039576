// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrailLesson_link__Iwb-q {\n  color: white;\n  font-weight: 700;\n  font-size: larger;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.TrailLesson_clients__table-tr__ohwjb:hover {\n  cursor: auto;\n  background: none !important;\n  color: white !important;\n}\n\n#TrailLesson_phone__icon__GnmkY {\n  color: lightgreen;\n}\n\n.TrailLesson_date__3WanS {\n  color-scheme: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/trailLessons/trailLessonDetails/TrailLesson.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".link {\n  color: white;\n  font-weight: 700;\n  font-size: larger;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.clients__table-tr:hover {\n  cursor: auto;\n  background: none !important;\n  color: white !important;\n}\n\n#phone__icon {\n  color: lightgreen;\n}\n\n.date {\n  color-scheme: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "TrailLesson_link__Iwb-q",
	"clients__table-tr": "TrailLesson_clients__table-tr__ohwjb",
	"phone__icon": "TrailLesson_phone__icon__GnmkY",
	"date": "TrailLesson_date__3WanS"
};
export default ___CSS_LOADER_EXPORT___;
